import { BiDoorOpen, BiError, BiVideo } from 'react-icons/bi'
import { FaFlag } from 'react-icons/fa6'
import { IoMdQrScanner } from 'react-icons/io'
import { Column } from 'react-table'

import { Avatar, Badge, Icon, Text, Tooltip } from '@chakra-ui/react'

import { AiOperator, Guards } from '@/components/icons'
import {
  DeviceType,
  Incident,
  IncidentSource,
  IncidentStatus,
} from '@/graphql/generated/schemas'
import { formatDateTime, formatTimeDistance } from '@/utils/formatDateTime'
import { getArchivedLabel, getArchivedLabelWithSN } from '@/utils/formats'
import {
  getIncidentSourceDisplayName,
  getIncidentStatusColor,
  getIncidentStatusDisplayName,
} from '@/utils/incidents'

import { IncidentOrigin } from '../types/types'
import { IS_FLAGGED } from '../utils/helpers'
import { IncidentGuardCell } from './IncidentGuardCell'

type IncidentEvent = {
  date: string
  facilityName: string
  floor: string
  incidentName: string
  incidentStatus: React.ReactNode
  source: React.ReactNode
}
interface FacilityI {
  name: string
  shortName: string
  archived: boolean
}

export function renderIncidentStatus(status: IncidentStatus) {
  return <Status status={status} />
}

export const Source = ({ source }: { source?: IncidentOrigin }) => {
  switch (source) {
    case DeviceType.Door:
      return <BiDoorOpen data-testid='source-door-cell' size={20} />
    case DeviceType.Camera:
      return <BiVideo data-testid='source-camera-cell' size={20} />
    case IncidentSource.Guard:
      return <Icon as={Guards} boxSize={5} strokeColor='#000' />
    case IncidentSource.QuickReport:
      return (
        <IoMdQrScanner color='black' data-testid='source-qr-cell' size={20} />
      )
    default:
      return <BiError size={20} />
  }
}

export const Status = ({ status }: { status?: IncidentStatus }) => {
  return (
    <Badge
      bgColor={getIncidentStatusColor(status)}
      color='#fff'
      data-testid='incidentsPage_table_statusCell'
      px='2'
      rounded='md'
      textTransform='capitalize'
    >
      {getIncidentStatusDisplayName(status)}
    </Badge>
  )
}

export const columns: Column<IncidentEvent>[] = [
  {
    Header: '',
    disableSortBy: false,
    accessor: IS_FLAGGED,
    Cell: ({ value }: { value: boolean }) => (
      <Tooltip
        hasArrow
        isDisabled={!value}
        label='AI Flagged Incident'
        openDelay={500}
        placement='auto'
        shouldWrapChildren
      >
        {value ? (
          <FaFlag
            color='#D01030'
            data-testid='incidentsPage_table_flaggedCell'
            size={18}
          />
        ) : null}
      </Tooltip>
    ),
    width: 40,
  },
  {
    Header: 'INC ID',
    disableSortBy: false,
    accessor: 'displayId',
    Cell: ({ value }: { value: string }) => (
      <Tooltip
        hasArrow
        label={value}
        openDelay={500}
        placement='auto'
        shouldWrapChildren
      >
        <Text data-testid='incidentsPage_table_displayIdCell' isTruncated>
          {value}
        </Text>
      </Tooltip>
    ),
    width: 90,
  },
  {
    Header: 'Date',
    disableSortBy: false,
    accessor: 'date',
    Cell: ({ value }: { value: string }) => (
      <Tooltip
        hasArrow
        label={`${formatDateTime(value)} • ${formatTimeDistance(value)}`}
        openDelay={500}
        placement='auto'
        shouldWrapChildren
      >
        <Text data-testid='incidentsPage_table_dateCell' isTruncated>
          {formatDateTime(value)}
        </Text>
      </Tooltip>
    ),
    width: 150,
  },
  {
    Header: 'Name',
    disableSortBy: false,
    accessor: 'incidentName',
    Cell: ({ value }: { value: string }) => (
      <Tooltip
        hasArrow
        label={value}
        openDelay={500}
        placement='auto'
        shouldWrapChildren
      >
        <Text data-testid='incidentsPage_table_incidentNameCell' isTruncated>
          {value}
        </Text>
      </Tooltip>
    ),
    width: 200,
  },
  {
    Header: 'Facility',
    disableSortBy: false,
    accessor: 'facilityName',
    Cell: ({ value }: { value: FacilityI }) => {
      return (
        <Tooltip
          hasArrow
          label={getArchivedLabelWithSN(value)}
          openDelay={500}
          placement='auto'
          shouldWrapChildren
        >
          <Text data-testid='incidentsPage_table_facilityNameCell' isTruncated>
            {getArchivedLabel(value?.name, value?.archived)}
          </Text>
        </Tooltip>
      )
    },
    width: 120,
  },
  {
    Header: 'Floor',
    disableSortBy: false,
    accessor: 'floor',
    Cell: ({ value }: { value: string }) => (
      <Tooltip
        hasArrow
        label={value}
        openDelay={500}
        placement='auto'
        shouldWrapChildren
      >
        <Text data-testid='incidentsPage_table_floorCell' isTruncated>
          {value}
        </Text>
      </Tooltip>
    ),
    width: 60,
  },
  {
    Header: 'Status',
    disableSortBy: false,
    accessor: 'incidentStatus',
    Cell: ({ value }: { value: IncidentStatus }) => (
      <Tooltip
        hasArrow
        label={getIncidentStatusDisplayName(value)}
        openDelay={500}
        placement='auto'
        shouldWrapChildren
      >
        {renderIncidentStatus(value)}
      </Tooltip>
    ),
    width: 80,
  },

  {
    Header: 'Source',
    disableSortBy: true,
    accessor: 'source',
    Cell: ({ value }: { value: IncidentSource }) => (
      <Tooltip
        hasArrow
        label={getIncidentSourceDisplayName(value)}
        openDelay={500}
        placement='auto'
        shouldWrapChildren
        textTransform='capitalize'
      >
        <Source source={value} />
      </Tooltip>
    ),
    width: 65,
  },
  {
    Header: 'Operator',
    disableSortBy: false,
    accessor: 'operator',
    Cell: ({ value }: { value: string }) => (
      <Tooltip
        hasArrow
        label={value ?? 'No operator'}
        openDelay={500}
        placement='auto'
        shouldWrapChildren
        textTransform='capitalize'
      >
        {value === 'AI Operator' ? (
          <Avatar
            backgroundColor='white'
            data-testid='operator-cell'
            icon={<AiOperator />}
            size='xs'
          />
        ) : (
          <Avatar data-testid='operator-cell' name={value} size='xs' />
        )}
      </Tooltip>
    ),
    width: 80,
  },
  {
    Header: 'Guard',
    disableSortBy: false,
    accessor: 'guard',
    Cell: ({ value }: { value: Incident }) => (
      <IncidentGuardCell incident={value} />
    ),
    width: 80,
  },
]
